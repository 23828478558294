import img1 from "../assets/partners/1.jpg";
import img10 from "../assets/partners/10.jpg";
import img11 from "../assets/partners/11.jpg";
import img12 from "../assets/partners/12.jpg";
import img13 from "../assets/partners/13.jpg";
import img15 from "../assets/partners/15.jpg";
import img16 from "../assets/partners/16.jpg";
import img18 from "../assets/partners/18.jpg";
import img19 from "../assets/partners/19.jpg";
import img2 from "../assets/partners/2.jpg";
import img3 from "../assets/partners/3.jpg";
import img4 from "../assets/partners/4.jpg";
import img5 from "../assets/partners/5.jpg";
import img6 from "../assets/partners/6.jpg";
import img7 from "../assets/partners/7.jpg";
import img8 from "../assets/partners/8.jpg";
import img9 from "../assets/partners/9.jpg";
export const partnersData = [
  {
    id: 1,
    img: img1,
    title: "Fire Guard",
  },
  {
    id: 2,
    img: img2,
    title: "Trident",
  },
  {
    id: 3,
    img: img3,
    title: "Bermad",
  },
  {
    id: 4,
    img: img4,
    title: "Patterson",
  },
  {
    id: 5,
    img: img5,
    title: "Naffco",
  },
  {
    id: 6,
    img: img6,
    title: "Darley",
  },
  {
    id: 7,
    img: img7,
    title: "New age",
  },
  {
    id: 8,
    img: img8,
    title: "Elkhart Brass",
  },
  {
    id: 9,
    img: img9,
    title: "Kussmaul Electronics",
  },
  {
    id: 10,
    img: img10,
    title: "Honeywell",
  },
  {
    id: 11,
    img: img11,
    title: "System Sensor",
  },
  {
    id: 12,
    img: img12,
    title: "Giacomini",
  },
  {
    id: 13,
    img: img13,
    title: "Notifier",
  },
  {
    id: 15,
    img: img15,
    title: "Reliable",
  },
  {
    id: 16,
    img: img16,
    title: "Viking",
  },
  {
    id: 18,
    img: img18,
    title: "Wilson & Cousins",
  },
  {
    id: 19,
    img: img19,
    title: "Kidde",
  },
];

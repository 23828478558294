import React from "react";
import styled from "styled-components";
import bg from "../assets/Images/aboutus.webp";

import TextSection from "../components/TextSection";
import OurVision from "../components/OurVision";
import { useTranslation } from "react-i18next";
const Contaniner = styled.div`
  width: 100%;
  position: relative;
  height: 40vh;
  img {
    width: 100%;
    height: auto;
  }
  background-image: url(${bg});
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  @media (max-width: 64em) {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    img {
      width: 100%;
      height: 100vh;
      object-fit: cover;
    }
  }
`;
const DarkOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
  background-color: rgba(191, 25, 33, 0.6);
`;

const AboutPage = () => {
  const { t } = useTranslation();
  return (
    <div className="overflow-hidden">
      <Contaniner>
        <DarkOverlay></DarkOverlay>
      </Contaniner>
      <TextSection />
      <OurVision />
      {/* <div className="bg-[#BF1922] ">
        <h1 className=" p-14 text-center lg:text-5xl text-2xl text-white">
          {t("doc")}
        </h1>
      </div> */}
    </div>
  );
};

export default AboutPage;

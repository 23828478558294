import "locomotive-scroll/dist/locomotive-scroll.css";

import { AnimatePresence } from "framer-motion";
import { useEffect, useRef, useState } from "react";
import { LocomotiveScrollProvider } from "react-locomotive-scroll";
import { ThemeProvider } from "styled-components";

import { Route, Routes } from "react-router-dom";
import GoToTopBtn from "./components/GoToTopBtn";
import Loader from "./components/Loader";
import Navbar from "./components/Navbar";
import ScrollTriggerProxy from "./components/ScrollTriggerProxy";
import About from "./sections/About";
import AboutPage from "./sections/AboutPage";
import Contactus from "./sections/Contactus";
import Footer from "./sections/Footer";
import Home from "./sections/Home";
import Projects from "./sections/Projects";
import Sectors from "./sections/Sectors";
import Services from "./sections/Services";
import Shop from "./sections/Shop";
import GlobalStyles from "./styles/GlobalStyles";
import { dark } from "./styles/Themes";
import { Toaster } from "react-hot-toast";
function App() {
  // useLocoScroll();
  const topRef = useRef(null);
  const containerRef = useRef(null);
  const [Loaded, setLoaded] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      setLoaded(true);
    }, 3000);
  }, []);

  return (
    <>
      <GlobalStyles />

      <ThemeProvider theme={dark}>
        <LocomotiveScrollProvider
          options={{
            smooth: true,
            smartphone: {
              smooth: true,
            },
            tablet: {
              smooth: true,
            },
          }}
          watch={[]}
          containerRef={containerRef}
        >
          <AnimatePresence>{Loaded ? null : <Loader />}</AnimatePresence>
          <main className="App" data-scroll-container ref={containerRef}>
            <ScrollTriggerProxy />
            <AnimatePresence>
              <Routes>
                <Route
                  path="/"
                  element={
                    <>
                      <Home key="home" />
                      <About key="about" />
                      <Services key="servives" />
                      <Sectors key="sectors" />
                      <Shop key="Shop" />
                      {/* <Projects key="Projects" /> */}
                      <Contactus key="contact us" />
                    </>
                  }
                />
                <Route
                  path="/about"
                  element={
                    <div className="overflow-hidden" ref={topRef}>
                      <div>
                        <Navbar key="Navbar" />
                      </div>
                      <AboutPage key="About Page" />
                    </div>
                  }
                />
              </Routes>
            </AnimatePresence>
            <Footer key="Footer" />
          </main>
          <GoToTopBtn />
        </LocomotiveScrollProvider>
      </ThemeProvider>
    </>
  );
}

export default App;

import React from "react";
import imgSector1 from "../assets/sectors/2.png";
import imgSector2 from "../assets/sectors/1.png";
import imgSector3 from "../assets/sectors/5-company.png";
import imgSector4 from "../assets/sectors/8-government.png";
import imgSector5 from "../assets/sectors/4-school.png";
import imgSector6 from "../assets/sectors/3.png";
import { useTranslation } from "react-i18next";
const Sectors = () => {
  const { t } = useTranslation();
  const ts = (key) => t(`sectors.${key}`);
  return (
    <div className="flex flex-col " id="sectors">
      <h1 className="text-center text-4xl my-6 flex items-center  justify-center">
        <span className="bg-[#E32832] text-white p-4 rounded-lg">
          {ts("title")}
        </span>
      </h1>
      <div className="flex flex-col lg:flex-row">
        <div className="flex flex-col gap-5 items-center justify-center w-full p-20 border border-[#E32832]">
          <img
            src={imgSector1}
            alt="sector img"
            style={{
              filter:
                "invert(23%) sepia(87%) saturate(2919%) hue-rotate(343deg) brightness(91%) contrast(96%)",
            }}
          />
          <p className="text-center">{ts("sector1")}</p>
        </div>
        <div className="flex flex-col gap-5 items-center justify-center w-full p-20 border border-[#E32832]">
          <img
            src={imgSector2}
            alt="sector img"
            style={{
              filter:
                "invert(23%) sepia(87%) saturate(2919%) hue-rotate(343deg) brightness(91%) contrast(96%)",
            }}
          />
          <p className="text-center">{ts("sector2")}</p>
        </div>
        <div className="flex flex-col gap-5 items-center justify-center w-full p-20 border border-[#E32832]">
          <img
            src={imgSector3}
            alt="sector img"
            style={{
              filter:
                "invert(23%) sepia(87%) saturate(2919%) hue-rotate(343deg) brightness(91%) contrast(96%)",
            }}
          />
          <p className="text-center">{ts("sector3")}</p>
        </div>
      </div>
      <div className="flex flex-col lg:flex-row">
        <div className="flex flex-col gap-5 items-center justify-center w-full p-20 border border-[#E32832]">
          <img
            src={imgSector4}
            alt="sector img"
            style={{
              filter:
                "invert(23%) sepia(87%) saturate(2919%) hue-rotate(343deg) brightness(91%) contrast(96%)",
            }}
          />
          <p className="text-center">{ts("sector4")}</p>
        </div>
        <div className="flex flex-col gap-5 items-center justify-center w-full p-20 border border-[#E32832]">
          <img
            src={imgSector5}
            alt="sector img"
            style={{
              filter:
                "invert(23%) sepia(87%) saturate(2919%) hue-rotate(343deg) brightness(91%) contrast(96%)",
            }}
          />
          <p className="text-center">{ts("sector5")}</p>
        </div>
        <div className="flex flex-col gap-5 items-center justify-center w-full p-20 border border-[#E32832]">
          <img
            src={imgSector6}
            alt="sector img"
            style={{
              filter:
                "invert(23%) sepia(87%) saturate(2919%) hue-rotate(343deg) brightness(91%) contrast(96%)",
            }}
          />
          <p className="text-center">{ts("sector6")}</p>
        </div>
      </div>
    </div>
  );
};

export default Sectors;

import { FaCircleArrowUp } from "react-icons/fa6";
import { useLocomotiveScroll } from "react-locomotive-scroll";
import styled from "styled-components";
const Button = styled.button`
  position: fixed;
  bottom: 50px;
  right: 25px;
  color: black;
  border-radius: 8px;
  cursor: pointer;

  :hover {
    transition: all 0.2s;
    transform: scale(1.1);
  }
`;
const GoToTopBtn = () => {
  const { scroll } = useLocomotiveScroll();

  const handleScroll = (id) => {
    let elem = document.querySelector(id);

    scroll.scrollTo(elem, {
      offset: -100,
      duration: 2000,
      easing: [0.25, 0.0, 0.35, 1.0],
    });
  };
  return (
    <Button onClick={() => handleScroll("#nav")}>
      <FaCircleArrowUp size={40} color="#22252A" />
    </Button>
  );
};

export default GoToTopBtn;

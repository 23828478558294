import { motion } from "framer-motion";
import React from "react";
import { useLocomotiveScroll } from "react-locomotive-scroll";
import styled from "styled-components";

import Logo from "../assets/Images/logo.png";
import { useTranslation } from "react-i18next";
import { BiSolidBookOpen, BiSolidPhoneCall } from "react-icons/bi";
import { MdOutlineEmail } from "react-icons/md";
import { FaLinkedin } from "react-icons/fa";
import { Link } from "react-router-dom";
import { SlBookOpen } from "react-icons/sl";
import companyProfile from "../assets/profile.pdf";
const Section = styled.section`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  overflow-x: hidden;

  background-color: #22252a;
  color: ${(props) => props.theme.text};
`;

const LogoContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  img {
    width: 20vw;
    height: auto;
  }

  h3 {
    font-family: "Binaria-Regular", sans-serif;
    font-size: ${(props) => props.theme.fontxxl};

    @media (max-width: 48em) {
      font-size: ${(props) => props.theme.fontxl};
    }
  }
`;

const FooterComponent = styled(motion.footer)`
  width: 80vw;

  @media (max-width: 48em) {
    width: 90vw;
  }
  ul {
    list-style: none;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    margin: 2rem;
    margin-top: 4rem;
    padding: 0 1rem;
    border-top: 1px solid ${(props) => props.theme.text};
    border-bottom: 1px solid ${(props) => props.theme.text};

    @media (max-width: 48em) {
      justify-content: center;
    }
  }

  li {
    padding: 2rem;
    font-size: ${(props) => props.theme.fontlg};
    text-transform: uppercase;
    cursor: pointer;
    transition: all 0.3s ease;
    &:hover {
      transform: scale(1.1);
    }

    @media (max-width: 48em) {
      padding: 1rem;
      font-size: ${(props) => props.theme.fontmd};
    }
  }
`;

const Bottom = styled.div`
  padding: 0.5rem 0;
  margin: 0 4rem;
  font-size: ${(props) => props.theme.fontlg};

  display: flex;
  justify-content: space-between;
  align-items: center;

  a {
    text-decoration: underline;
  }

  @media (max-width: 64em) {
    flex-direction: column;
    justify-content: center;
    span {
      transform: none !important;
    }
  }

  @media (max-width: 48em) {
    font-size: ${(props) => props.theme.fontmd};
  }
`;

const Footer = () => {
  const { scroll } = useLocomotiveScroll();
  const { t, i18n } = useTranslation();

  const tn = (key) => t(`navbar.${key}`);
  const handleScroll = (id) => {
    let elem = document.querySelector(id);

    if (window.location.pathname === "/") {
      scroll.scrollTo(elem, {
        offset: -100,
        duration: 2000,
        easing: [0.25, 0.0, 0.35, 1.0],
      });
    } else {
      window.location.href = `/${id}`;
    }
  };

  return (
    <Section className="p-4 gap-5 h-[100vh] lg:h-auto ">
      <div className="flex flex-col lg:flex-row  items-center justify-around w-full gap-[100px] lg:gap-0">
        <div className="flex flex-col items-center lg:items-start gap-4">
          <h2 className="text-xl lg:text-2xl">{t("links")}</h2>
          <ul className="flex gap-4 cursor-pointer">
            <li
              aria-hidden="true"
              onClick={() => handleScroll("#fixed-target")}
            >
              {tn("about")}
            </li>
            <li aria-hidden="true" onClick={() => handleScroll("#services")}>
              {tn("services")}
            </li>
            <li aria-hidden="true" onClick={() => handleScroll("#contact")}>
              {tn("contact")}
            </li>
          </ul>
          <div>
            <Link
              to={companyProfile}
              target="_blank"
              className="text-white flex items-center gap-3"
            >
              <SlBookOpen size={24} />
              {t("companyProfile")}
            </Link>
          </div>
        </div>
        <img
          src={Logo}
          alt="logo"
          className={`max-w-[50%] lg:max-w-[14%]  ${
            i18n.language === "ar" ? "lg:-mr-[115px]" : "lg:-ml-[115px]"
          } m-0`}
        />

        <div className="flex gap-4 cursor-pointer">
          <a href={"https://wa.me/+966543200045"} target="_blank">
            <BiSolidPhoneCall size={32} />
          </a>
          <a href={"mailto:info@professional-address.com"} target="_blank">
            <MdOutlineEmail size={32} />
          </a>
          <a
            href={"https://www.linkedin.com/company/professional-address"}
            target="_blank"
          >
            <FaLinkedin size={32} />
          </a>
        </div>
      </div>
      <div className="flex items-center justify-center">
        <p>© 2024 All Rights Reserved</p>
      </div>
      {/* <LogoContainer>
        <img
          width="300"
          height="300"
          src={Logo}
          alt="Wibe"
          data-scroll
          data-scroll-speed="2"
          className="rounded-lg p-2"
        />
        <h3 data-scroll data-scroll-speed="-1" className="bg-[#393E46]">
          professional address
        </h3>
      </LogoContainer>
      <FooterComponent
        initial={{ y: "-400px" }}
        whileInView={{ y: 0 }}
        viewport={{ once: false }}
        transition={{
          duration: 1.5,
        }}
      >
        <ul>
          <li aria-hidden="true" onClick={() => handleScroll("#home")}>
            {tn("home")}
          </li>
          <li aria-hidden="true" onClick={() => handleScroll(".about")}>
            {tn("about")}
          </li>
          <li aria-hidden="true" onClick={() => handleScroll("#shop")}>
            {tn("partners")}
          </li>
          <li aria-hidden="true" onClick={() => handleScroll(".new-arrival")}>
            {tn("business-units")}
          </li>
        </ul>
        <Bottom>
          <span
            data-scroll
            data-scroll-speed="2"
            data-scroll-direction="horizontal"
          >
            &copy; {new Date().getFullYear()} All Rights Reserved.
          </span>
          <span
            data-scroll
            data-scroll-speed="-2"
            data-scroll-direction="horizontal"
          >
            Made with &hearts; by{" "}
            <a
              href="https://mohamed-alsayyd.vercel.app"
              target={"_blank"}
              rel="dofollow noreferrer"
            >
              Mohamed alsayyd
            </a>
          </span>
        </Bottom>
      </FooterComponent> */}
    </Section>
  );
};

export default Footer;
